export default [
    {
        path: "/driver",
        name: "driver",
        component: () => import("@/views/driver/PageGuard.vue"),
        children: [
            {
                path: "check",
                name: "check",
                component: () => import("@/views/driver/journal/JournalCheckHome.vue"),
                meta: { auth : false },
            },
            {
                path: "check/journal/:vehicleNo/:workDate/:driverNo",
                name: "check",
                component: () => import("@/views/driver/journal/JournalCheck.vue"),
                meta: { auth : false },
            },
            // 유류비기입
            {
                path: "fuelcost",
                name: "fuelcost",
                component: () => import("@/views/driver/fuelcost/FuelCostHome.vue"),
                meta: { auth : false },
            },
            {
                path: "fuelcost/add",
                name: "fuelcost/add",
                component: () => import("@/views/driver/fuelcost/FuelCostAdd.vue"),
                meta: { auth : false },
            },
            {
                path: "fuelcost/modify/:uuid",
                name: "fuelcost/modify",
                component: () => import("@/views/driver/fuelcost/FuelCostModify.vue"),
                meta: { auth : false },
            },
            // 운행내역
            {
                path: "drivingHistory",
                name: "drivingHistory",
                component: () => import("@/views/driver/drivingHistory/DrivingHistory.vue"),
                meta: { auth : false },
            },
        ],
    },
];