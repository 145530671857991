import html2pdf from 'html2pdf.js'
const methods = {
  htmlToPdf: (location, fileName) => {
    html2pdf()
      .set({
        // margin: [10, 0, 10, 0],
        filename: navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1 ? `${fileName}.pdf` : fileName,
        pagebreak: { mode: 'avoid-all' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
            width: 1400,
            useCORS: true,
            scrollY: 0,
            scale: 1,
            allowTaint: false,
        },
        jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4'},
      })
      .from(location)
      .save()
  },
}

export default {
  install(Vue) {
    Vue.prototype.$htmlToPdf = methods.htmlToPdf
  },
};