import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import vueMoment from 'vue-moment'
import { getCookie } from "@/utils/cookies.js";
import { instanceWithAuth } from '@/api/index.js'
import AlertDialog from "@/components/common/AlertDialog.vue";
import TablePagination from "@/components/common/Pagination.vue";
import htmlToPdf from '@/plugins/htmlTopdf.js';

Vue.use(htmlToPdf);
Vue.component("AlertDialog", AlertDialog);
Vue.component("TablePagination", TablePagination);
Vue.use(vueMoment)
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.instanceWithAuth = instanceWithAuth;
Vue.mixin({
    filters: {
        commaSeparator(val) {
            const parts = val.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    },
    methods: {
		comma(obj) {
			var regx = new RegExp(/(-?\d+)(\d{3})/);
			var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
			var strArr = obj.split('.');
			while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
				//정수 부분에만 콤마 달기 
				strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
			}
			if (bExists > -1) {
				//. 소수점 문자열이 발견되지 않을 경우 -1 반환
				obj = strArr[0] + "." + strArr[1];
			} else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
				obj = strArr[0];
			}
			return obj;//문자열 반환
		},
		//콤마 풀기
		uncomma(str) {
			str = "" + str.replace(/,/gi, ''); // 콤마 제거 
			str = str.replace(/(^\s*)|(\s*$)/g, ""); // trim()공백,문자열 제거 
			return (new Number(str));//문자열을 숫자로 반환
		},
        /*------------------------------------------------------------------------------
        * 함수명 : replace()
        * 처리내용 : GET 전송시 특수문자함께 전송하는 방법
        ------------------------------------------------------------------------------*/
        replaceParam(inum) {
            inum = inum.replace(/#/g,"%23");
            inum = inum.replace(/&/g,"%26");
            inum = inum.replace(/\+/g,"%2B");
            return inum;
        },
        /*----------------------------------------------------------------------------*/
        /**********************************************************************
         *  @method		: 토큰을 쿠키에서 로드
         *  @param      { String }      : 포맷 형태
         *  @returns    { Object }		: 포맷 형태에 따른 객체
         *********************************************************************/
        getAuthToken(use="headers") {
            const authToken = getCookie("token")
            // 저장된 토큰값이 없을때
            if (authToken == null) {
                this.$store.dispatch('logout')
                this.$router.push("/");
                return false
            }
            // 로그인 처리
            // this.$store.commit("adminLogin");

            // Axios 호출 헤더 포맷
            if (use == "headers") {
                const res = {
                    headers: {
                        "Authorization": "Bearer " + authToken,
                    }
                }
                return res;
            }
            // 토근 발급용 포맷
            if (use == "token") {
                const res = {
                "Authorization": "Bearer " + authToken
                }
                return res;
            }
        },

        /**********************************************************************
         *  @method		: DateTime 문자열 변환 
         *  @param      { String }
         *  @returns    { String }      :YYYY-MM-DD HH:MM:SS
         *********************************************************************/
        dateFormatting(date) {
            try {
                const res = date.replace("T", " ");
                return res
            } catch (e) {
                return ""
            }
        },

        /**********************************************************************
         *  @method     : Axios error handling
         *  @param      { Number }
         *  @returns    { object }
         *********************************************************************/
		axiosErrorHandling(code) {
			switch(code) {
				case 400:
					return { status: "error", code: 400, msg: "잘못된 요청입니다." };
				case 401:
					return { status: "error", code: 401, msg: "로그인 만료" };
				case 404:
					return { status: "error", code: 404, msg: "Not Found" };
				case 500:
					return { status: "error", code: 500, msg: "서버 에러" };
				default:
					return { status: "error", code: code , msg: "unknown" };
			}
		},
        /**********************************************************************
         *  @method     : 공백, null, undefined 체크
         *  @param      { string }
         *  @returns    { boolean }
         *********************************************************************/
		isEmpty(args) {
            if (args === undefined || args == null || args == "") {
                return true;
            } else {
                return false;
            }
        },
        /**********************************************************************
         *  @method     : Date Time Formatting
         *  @param      { string }
         *  @returns    { string }
         *********************************************************************/
        // 2022. 08. 25 신우균: T문자 제거 로직 추가.
        dbDateTimeFormatting(dateTime) {
            let resp = dateTime.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g, '$1-$2-$3 $4:$5:$6');
            resp = resp.replace("T", " ");
            return resp;
            // return dateTime.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g, '$1-$2-$3 $4:$5:$6');
        },
        /**********************************************************************
         *  @method     : Date Formatting
         *  @param      { string }
         *  @returns    { string }
         *********************************************************************/
        dbDateFormatting(date) {
            return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
        },
        /**********************************************************************
         *  @method     : Time Formatting
         *  @param      { string }
         *  @returns    { string }
         *********************************************************************/
        dbTimeFormatting(time) {
            return time.replace(/(\d{2})(\d{2})(\d{2})/g, '$1:$2:$3');
        },

        /**********************************************************************
         *  @method     : Amount Formatting
         *  @param      { string }
         *  @returns    { string }
         *********************************************************************/
        dbAmountCommas(val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    }
})

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
