import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
// 승무사원
import Driver from '@/router/driver';
import login from './login';

import { getCookie } from "@/utils/cookies.js";
import { jwtDecode } from "@/utils/jwt.js";
import { setRefeshToken } from '@/api/common/jwt.js'
import { setCookie } from '@/utils/cookies.js'
Vue.use(VueRouter)

const routes = [
	{
		path: '/main',
		component: () => import("@/views/UserPageGuard.vue"),
		children: [
			{
				path: "",
				name: "index",
				component: () => import("@/views/Index.vue"),
                meta: { auth : true },
			},          
			// 승무사원
			...Driver,
		]
	},
    ...login,
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});


/******************************************************************************
 *  Navigation Guard
 *****************************************************************************/
router.beforeEach(async (to, from, next) => {
    if(to.query.token != undefined || to.query.token != null) setCookie("token", to.query.token , 30)
    if(to.query.refreshToken != undefined || to.query.refreshToken != null) setCookie("refreshToken", to.query.refreshToken , 30)
    
    const authToken = getCookie("token")
    let decode = await jwtDecode(authToken)
    if(to.path != "/logout") {
        if(authToken != null) {
            await store.commit("userLogin", decode)
            await store.dispatch("getMenuList")
        }
    }

    // 권한체크
    if(store.state.menuInfo.length > 0) {
        try {
            store.state.menuInfo.forEach(res1 => {
                res1.menuList.forEach(res2 => {
                    router.options.routes[0].children.forEach((route1, routeIndex1) => {
                        if(res2.url.indexOf(route1.path) > -1) {
                            if(route1.path != '') {
                                route1.children.forEach((route2, routeIndex2) => {
                                    if(res2.url.indexOf(route2.path.indexOf('/') > -1 ? route2.path.substring(1).split("/", 1) : route2.path ) > -1) {
                                        router.options.routes[0].children[routeIndex1].children[routeIndex2].meta.auth = true
                                    }
                                })
                            }
                        }
                    })
                })
            })          
        }catch {
            store.commit('userLogout')
            throw alert("Auth Error")
        }
    }

    if (to.matched.some(record => record.meta.auth)) {
        // 쿠키에 저장된 토큰이 없을 경우
        if (authToken == null) {
            store.commit('userLogout')
            next("/")
        }
        // 쿠키에 저장된 토큰은 있고, 로그인 상태 식별자가 false일 경우
        // 추가로 다른 탭에서 접속했을 때 케이스 처리
        if (store.getters.isLogin.status == false) {   
            // decode 실패
            if (decode == false) {
                alert("로그인 후 이용해주시기 바랍니다.")
                store.dispatch('logout')
            }
            // 토근이 만료된 경우
            else if (Date.now() > decode.exp * 1000) {
                await setRefeshToken()
                await store.dispatch("getMenuList")
                next()
            } else {
                await store.commit("userLogin", decode)
                await store.dispatch("getMenuList")
                next()
            }
        } else {
            next()
        }
    } else if(to.matched.some(record => record.meta.anonymous)){
        if(decode == false) {
            next()
        }else {
            if(store.getters.isLogin.status == true) {
                if(to.path == '/login') router.go(-1)
            }
        }
        next()
    } else {
        alert("존재하지 않은 페이지입니다.")
        router.go(-1)
    }
});

/******************************************************************************
 *  동일 라우터 주소로 호출시 에러 예외 처리
 *****************************************************************************/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(() => {});
};

export default router
