/******************************************************************************
 *  @method     : JWT token decode
 *  @param      { string }
 *  @returns    { object }
 *****************************************************************************/
 function jwtDecode(token) {
    try {
        // 2022.09.29 신우균, atob 파싱 실패 오류 해결 
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const result = JSON.parse(jsonPayload);
        return result;
    } catch {
        return false;
    }
}

export { jwtDecode }