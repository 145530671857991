import axios from 'axios'
import { setInterceptors, setAccessInterceptors, setRefeshInterceptors, } from './common/interceptors'

// accessToken Error 처리 없이 사용하고 싶을때
function createInstance() {
	const instance = axios.create()
	return setInterceptors(instance)
}
export const instance = createInstance()

// accessToken Error 처리 (기본 사용)
function createInstanceWithAuth() {
	const instance = axios.create()
	return setAccessInterceptors(instance)
}
export const instanceWithAuth = createInstanceWithAuth()

// refreshToken Error 처리
function createTokenInstanceWithAuth() {
	const instance = axios.create()
	return setRefeshInterceptors(instance)
}
export const tokenInstanceWithAuth = createTokenInstanceWithAuth()