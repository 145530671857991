import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { instance } from "@/api/index.js"
import { setCookie } from "@/utils/cookies.js"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({ paths: ["isLogin", "menuInfo", "callClientInfo"] })],

    state: {
        navbarDisplay: false,
        pageData: {
            "currentPage": null,
            "scrollY": null,
        },
        isLogin: {
            "status": false,
            "userName": null,
            "userId":null,
            "userRoleType": null,
        },
        menuInfo: [],
        callClientInfo: {
            name: null,
            phone: null,
            description: null
        }
    },
    getters: {
        getPageData(state) {
            return state.pageData
        },
        isLogin(state) {
            return state.isLogin
        },
        menuInfo(state) {
            return state.menuInfo
        },
        callClientInfo(state) {
            return state.callClientInfo
        }
    },
    mutations: {
        userLogin(state, userData) {
            state.isLogin.status = true
            state.isLogin.userName = userData.name
            state.isLogin.userId = userData.empNo
            state.isLogin.userRoleType = userData.empType
        },
        userLogout(state) {
            setCookie("token", "", 0)
            setCookie("refreshToken", "", 0)
            setCookie("callNumber", "", 0)      

            state.isLogin.status = false
            state.isLogin.userName = null
            state.isLogin.userId = null
            state.isLogin.userRoleType = null
            state.menuInfo = []

            location.href = "/logout"
        },
        setPageData(state, value) {
            state.pageData = value
        },
        setMenuInfo(state, payload) {
            state.menuInfo = payload
        },
        setCallClientInfo(state, payload) {
            state.callClientInfo = payload
        }
    },
    actions: {
        async logout() {
            const URI = `/api/emp/logout`
            await instance.post(URI).catch(err => { console.log(err) })
            this.commit('userLogout')
        },
        async getMenuList() {
            const URI = `/api/route/menu/list`
            const response = await instance.get(URI).catch(err => { 
                console.log(err)
                alert("권한 정보를 불러오지 못했습니다.")
                return this.commit('userLogout')
            })
            if(response) this.commit('setMenuInfo', response.data.data)
        }
    },
    modules: {
    }
})
