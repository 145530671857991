/******************************************************************************
 *  @method     : Get cookie
 *  @param      { string }		:name
 *  @returns    { string }		:value
 *****************************************************************************/
function getCookie(name) {
    const value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return value ? value[2] : null;
}

/**********************************************************************
 *  @method     : Set cookie
 *  @param      { string }		:name
 *  @param      { string }		:value
 *  @param      { number }		:expire(ms)
 *  @returns    { undefined }
 *********************************************************************/
function setCookie(name, value, exp) {
    const date = new Date();
    date.setTime(date.getTime() + exp*24*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
}

export {
    getCookie,
    setCookie,
}