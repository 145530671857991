export default [
    {
        path: "/",
        name: "index",
        component: () => import("@/views/login/Index.vue"),
        meta: { anonymous : true },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/Login.vue"),
        meta: { anonymous : true },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/login/Logout.vue"),
        meta: { anonymous : true },
    },
];