import { getCookie } from "@/utils/cookies.js"
import  store from "@/store/index.js"
import { setRefeshToken } from '@/api/common/jwt.js'
import { instanceWithAuth } from '@/api/index.js'

// accessToken Error 처리 없이 사용하고 싶을때
function setInterceptors(instance) {
    instance.interceptors.request.use(
		function(config) {
            config.headers.Authorization = "Bearer " + getCookie("token")
			return config
		},
		function(error) {
			return Promise.reject(error)
		},
	)

	instance.interceptors.response.use(
		function(response) {
			return response
		},
		function(error) {
			return Promise.reject(error)
		},
	)
	return instance
}

// accessToken Error 처리 (기본 사용)
function setAccessInterceptors(instance) {
    let data = {}
    instance.interceptors.request.use(
        function(config) {
            data = config
            if(config.excel == true){
                config.responseType = 'blob',
                config.headers = {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                }
            }
            config.headers.Authorization = "Bearer " + getCookie("token") 
            return config
        },
        function(error) {
            return Promise.reject(error)
        },
    )

    instance.interceptors.response.use(
        function(response) {
            return response
        },
        async function(error) {
            // 존재하지 않은 데이터입니다.
            if(error.response.data.code == 10201) {
                alert("존재하지 않은 데이터입니다")
                return location.href = "/"
            }
            // 유효하지 않은 토큰입니다.
            if(error.response.data.code == 90201) {
                await setRefeshToken()
                await store.dispatch("getMenuList")
                return instanceWithAuth(data)
            }
            // 다른기기에서 로그인중인 토큰입니다.
            if(error.response.data.code == 90204) {
                alert("다른기기에서 로그인중입니다")
                return store.dispatch('logout')
            }
            return Promise.reject(error)
        },
    )
    return instance
}

// refrshToken Error 처리
function setRefeshInterceptors(instance) {
    instance.interceptors.request.use(
		function(config) {
            config.headers.Authorization = "Bearer " + getCookie("refreshToken")
			return config
		},
		function(error) {
            // 유효하지 않은 토큰입니다.
            if(error.response.data.code == 90201) {
                alert("로그인 기간이 만료되었습니다")
                return store.dispatch('logout')
            }
            // 리프레시 토큰을 찾을 수 없습니다. 
            if(error.response.data.code == 90203) {
                alert("로그인 정보를 찾을 수 없습니다")
                return store.dispatch('logout')
            }
			return Promise.reject(error)
		},
	)

	instance.interceptors.response.use(
		function(response) {
			return response
		},
		function(error) {
			return Promise.reject(error)
		},
	)
	return instance
}

export {
    setInterceptors,
    setAccessInterceptors,
    setRefeshInterceptors,
}