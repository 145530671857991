import { tokenInstanceWithAuth } from "@/api/index.js"
import { setCookie } from "@/utils/cookies.js"
import  store from "@/store/index.js"

async function setRefeshToken() {
    const URI = `/api/auth/refresh`
    const result = await tokenInstanceWithAuth.post(URI).catch(err => {
        if(err) {
            alert("로그인 정보가 일치하지 않습니다")
            return store.dispatch('logout')
        }
    })
    if(result) {
        const accessToken = result.data.data.accessToken
        setCookie("token", accessToken, 30)
    }
}

export { setRefeshToken }